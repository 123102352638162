/* Body */
body                {font-family: "Open Sans", sans-serif !important;}
body                {background-color: rgb(36, 36, 36) !important;}
body                {color: white !important;}

/* Background Colours */
.bg-primary         {background-color: red !important;}
.bg-secondary       {background-color: rgb(100, 100, 100) !important;}
.bg-success         {background-color: #14BD14 !important;}
.bg-info            {background-color: #0000EE !important;}
.bg-warning         {background-color: yellow !important;}
.bg-danger          {background-color: #CA1414 !important;}
.bg-white           {background-color: white !important;}
.bg-dark            {background-color: black !important;}
.bg-transparent     {background-color: transparent !important;}

/* Text Colours */
.text-primary       {color: red !important;}
.text-secondary     {color: "colour" !important;}
.text-success       {color: #14BD14 !important;}
.text-info          {color: #0000EE !important;}
.text-warning       {color: yellow !important;}
.text-danger        {color: #CA1414 !important;}
.text-white         {color: white !important;}
.text-dark          {color: black !important;}

/* Button */
.btn-primary        {background-color: #CC0033 !important;}
.btn-secondary      {background-color: "colour" !important;}
.btn-success        {background-color: #14BD14 !important;}
.btn-info           {background-color: #0000EE !important;}
.btn-warning        {background-color: yellow !important;}
.btn-danger         {background-color: #CA1414 !important;}
.btn-white          {background-color: white !important;}
.btn-dark           {background-color: black !important;}

/* Border colours */
.border-primary     {border-color: red !important;}
.border-secondary   {border-color: "colour" !important;}
.border-success     {border-color: #14BD14 !important;}
.border-info        {border-color: #0000EE !important;}
.border-warning     {border-color: yellow !important;}
.border-danger      {border-color: #CA1414 !important;}
.border-white       {border-color: white !important;}
.border-dark        {border-color: black !important;}

/* Shadow */
.shadow             {box-shadow: 0.15rem 0.15rem 0 rgba(0, 0, 0, 0.4) !important;}

/* Flex Settings */
.flex-row-reverse           {flex-direction: row-reverse !important;}
.flex-column                {flex-direction: column !important;}

/* Flex Wraping*/
.Flex-wrap                       {flex-wrap: wrap !important;}

/* Flex Wraping*/
.flex-row                       {flex-direction: row !important;}

/* Justify & Align */
.justify-content-between    {justify-content: space-between !important;}
.justify-content-center     {justify-content: center !important;}
.align-items-center         {align-items: center !important;}
.align-item-start           {align-items: flex-start !important}



/* Padding */
.pr-2, .px-2                {padding-right: 2rem !important;}
.pl-2, .px-2                {padding-left: 2rem !important;}
.p-1-5                      {padding: 1.5rem !important;}

.pr-auto, .py-auto          {padding-right: auto !important}
.pl-auto, .py-auto          {padding-left: auto !important}

/* Border */


/* Margin */
.mr-auto, .my-auto          {margin-right: auto !important;}
.ml-auto, .my-auto          {margin-left: auto !important;}
.mt-1, .my-1                {margin-top: 1rem !important}
.mb-1, .my-1                {margin-bottom: 1rem !important}
.mr-1, .my-1                {margin-right: 1rem !important}
.ml-1, .my-1                {margin-left: 1rem !important}

.mt-2, .my-2                {margin-top: 2rem !important}
.mb-2, .my-2                {margin-bottom: 2rem !important}
.mr-2, .my-2                {margin-right: 2rem !important}
.ml-2, .my-2                {margin-left: 2rem !important}

.mt-0-25, .my-0-25          {margin-top: 0.25rem !important;}
.mb-0-25, .my-0-25          {margin-bottom: 0.25rem !important;}

.mt-0-5, .my-0-5            {margin-top: 0.5rem !important;}
.mb-0-5, .my-0-5            {margin-bottom: 0.5rem !important;}



/* Positon Settings*/
.position-relative          {position: relative !important;}
.position-absolute          {position: absolute !important;}


/* Rounded corners & Width */
.w-100              {width: 100% !important;}
.w-70               {width: 70% !important}
.w-20               {width: 20% !important;}
.w-15               {width: 15% !important;}
.w-10               {width: 10% !important;}
.w-5                {width: 5% !important;}
.rounded-0          {border-radius: 0 !important;}
.pill-rounding      {border-radius: 40px !important;}


/* Row */
/* .row{
    width: 100%;    
} */
.rowMargin{
    margin: 2rem !important;
}

/* Display */
.d-block {display: block;}

/* Removed imprtant from d-block */