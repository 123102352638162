/* Image */
.frontimg{
    width: 100% !important;
    align-self: center !important;
}

/* Button */
.f-buttonFirst{
    justify-content: center !important;
    background-color: transparent !important;
    color: white !important;
    text-shadow: 2px 2px 10px black !important;
    font-size: 30px !important;
    font-weight: 600 !important;
    text-decoration: underline !important;

    position: absolute !important;
    top: 10% !important;
    bottom: 10% !important;
    left: 10% !important;
    right: 10% !important;
    width: 80% !important;
    border: none !important;
}
.f-buttonSecond{
    justify-content: center !important;
    background-color: transparent !important;
    color: white !important;
    text-shadow: 2px 2px 10px black !important;
    font-size: 30px !important;
    font-weight: 600 !important;
    
    position: absolute !important;
    top: 10% !important;
    bottom: 10% !important;
    left: 10% !important;
    right: 10% !important;
    width: 80% !important;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
    border: none !important;
}
.f-buttonThird{
    justify-content: center !important;
    background-color: transparent !important;
    color: white !important;
    text-shadow: 2px 2px 10px black !important;
    font-size: 30px !important;
    font-weight: 600 !important;
    text-decoration: underline !important;
    
    position: absolute !important;
    top: 10% !important;
    bottom: 10% !important;
    left: 10% !important;
    right: 10% !important;
    width: 80% !important;
    border: none !important;
}
.btn{
    background-color: #CC0033 !important;
    color: white !important;
    font-size: 16px;
    font-weight: bold;
    
}
.btn:hover{
    background-color: rgb(179, 41, 23);
    color: black;
}

/* ContactForm*/
.contactForm-Mobile{
    width: 90% !important;
    justify-content: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
.ContactForm-ButtonMobile{
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important;
    align-content: center !important;
    text-align: center !important;
    margin-top: 1rem !important;
}


/* Text */
.F-Title{
    justify-content: center !important;
    text-align: center !important;
    flex-direction: column !important;
    font-size: 30px !important;
    font-weight: 500 !important;
    text-align: center !important;
    position: absolute !important;
    top: 60px !important;
    left: 30px !important;
    text-shadow: black 2px 2px 10px !important;
}
.F-TitleFirst{
    justify-content: center !important;
    text-align: center !important;
    flex-direction: column !important;
    font-size: 30px !important;
    font-weight: 500 !important;
    text-align: center !important;
    position: absolute !important;
    top: 60px !important;
    left: 30px !important;
    text-shadow: black 2px 2px 10px !important;
}

/* Only Mobile visible text*/
.Contact-Title{
    font-size: 30px !important;
    justify-content: center !important;
    text-align: center !important;
    margin-top: 2rem !important;
    padding-top: 3rem !important;
    padding-bottom: 1rem !important;
    margin-bottom: 1rem !important;
    text-decoration: underline;
}
.Aboutmobile-Title{
    font-size: 30px !important;
    justify-content: center !important;
    text-align: center !important;
    margin-top: 2rem !important;
    padding-top: 3rem !important;
    padding-bottom: 1rem !important;
    margin-bottom: 1rem !important;
    text-decoration: underline;
}
.Aboutmobile-Text{
    justify-content: center !important;
    text-align: center !important;
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;

}
.AboutMobileImage{
    width: 90% !important;
    
    margin-left: auto !important;
    margin-right: auto !important;

    position: relative;
    left: 5%;
}

/* Iphone mobile */
@media(min-width: 440px){

}
/* Mobile */
@media(min-width: 520px){
    .frontimgpc{
        width: 80% !important;
        align-content: center !important;
        left: 20% !important;
        right: 10% !important;
    }
    .Aboutmobile-Title{
        font-size: 35px !important;
        justify-content: center !important;
        text-align: center !important;
        margin-top: 2rem !important;
        padding-top: 3rem !important;
        padding-bottom: 1rem !important;
        margin-bottom: 1rem !important;
        text-decoration: underline;
    }
    .Aboutmobile-Text{
        font-size: 20px !important;
        justify-content: center !important;
        text-align: center !important;
        width: 90% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    
    }
    .Contact-Title{
        font-size: 35px !important;
        justify-content: center !important;
        text-align: center !important;
        margin-top: 2rem !important;
        padding-top: 3rem !important;
        padding-bottom: 1rem !important;
        margin-bottom: 1rem !important;
        text-decoration: underline;
    }

}

@media(min-width: 576px){
    .f-buttonFirstPc{
        justify-content: center !important;
        text-align: center !important;
        position: absolute !important;
        top: 230px !important;
        left: 30% !important;
        border: none ;
    }
    .Aboutmobile-Title{
        font-size: 35px !important;
        justify-content: center !important;
        text-align: center !important;
        margin-top: 2rem !important;
        padding-top: 3rem !important;
        padding-bottom: 1rem !important;
        margin-bottom: 1rem !important;
        text-decoration: underline;
    }
    .Aboutmobile-Text{
        font-size: 20px !important;
        justify-content: center !important;
        text-align: center !important;
        width: 90% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    
    }
    .AboutMobileImage{
        width: 90% !important;
        
        margin-left: auto !important;
        margin-right: auto !important;
    
        position: relative;
        left: 5%;
    }
    .Contact-Title{
        font-size: 35px !important;
        justify-content: center !important;
        text-align: center !important;
        margin-top: 2rem !important;
        padding-top: 3rem !important;
        padding-bottom: 1rem !important;
        margin-bottom: 1rem !important;
        text-decoration: underline;
    }

}
@media(min-width: 600px){
    .f-buttonFirstPc{
        justify-content: center !important;
        text-align: center !important;
        position: absolute !important;
        top: 230px !important;
        left: 30% !important;
        border: none ;
    }
    .Aboutmobile-Title{
        font-size: 35px !important;
        justify-content: center !important;
        text-align: center !important;
        margin-top: 2rem !important;
        padding-top: 3rem !important;
        padding-bottom: 1rem !important;
        margin-bottom: 1rem !important;
        text-decoration: underline;
    }
    .Aboutmobile-Text{
        font-size: 20px !important;
        justify-content: center !important;
        text-align: center !important;
        width: 70% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    
    }
    .AboutMobileImage{
        width: 70% !important;
        
        margin-left: auto !important;
        margin-right: auto !important;
    
        position: relative;
        left: 15%;
    }
    .Contact-Title{
        font-size: 35px !important;
        justify-content: center !important;
        text-align: center !important;
        margin-top: 2rem !important;
        padding-top: 3rem !important;
        padding-bottom: 1rem !important;
        margin-bottom: 1rem !important;
        text-decoration: underline;
    }

}

@media (min-width: 768px){
    .F-Title{
        justify-content: center !important;
        text-align: center !important;
        flex-direction: column !important;
        font-size: 40px !important;
        font-weight: 500 !important;
        text-align: center !important;
        position: absolute !important;
        top: 40px !important;
        left: 10% !important;
        right: 10% !important;
        text-shadow: black 2px 2px 10px !important;
    }
    .F-Undertext{
        justify-content: center;
        text-align: center;
        flex-direction: column;
        font-size: 18px;
        position: absolute;
        top: 140px;
        left: 10%;
        width: 80%;
        text-shadow: black 2px 2px 10px;
    
    }
    .f-button{
        justify-content: center !important;
        text-align: center !important;
        position: absolute !important;
        top: 290px !important;
        left: 30% !important;
        right: 30% !important;
        border: none ;
    }
    .btn{
        background-color: #CC0033 !important;
        color: white !important;
        font-size: 18px !important;
        font-weight: bold;
        width: 30% !important;
    }
    .Contact-Title{
        font-size: 35px !important;
        justify-content: center !important;
        text-align: center !important;
        margin-top: 2rem !important;
        padding-top: 3rem !important;
        padding-bottom: 1rem !important;
        margin-bottom: 1rem !important;
        text-decoration: underline;
    }
}

/* Desktop */
@media(min-width: 1000px) {
    .F-Title{
        justify-content: center !important;
        text-align: center !important;
        flex-direction: column !important;
        font-size: 50px !important;
        font-weight: 500 !important;
        text-align: center !important;
        position: absolute !important;
        top: 60px !important;
        left: 10% !important;
        right: 10% !important;
        text-shadow: black 2px 2px 10px !important;
    }
    .F-Undertext{
        justify-content: center;
        text-align: center;
        flex-direction: column;
        font-size: 23px;
        position: absolute;
        top: 180px;
        left: 10%;
        width: 80%;
        text-shadow: black 2px 2px 10px;
    
    }
    .f-button{
        justify-content: center !important;
        text-align: center !important;
        position: absolute !important;
        top: 380px !important;
        left: 30% !important;
        right: 30% !important;
        border: none ;
    }
    .btn{
        background-color: #CC0033 !important;
        color: white !important;
        font-size: 20px !important;
        font-weight: bold;
        width: 30% !important;
    }
    .Contact-Title{
        font-size: 35px !important;
        justify-content: center !important;
        text-align: center !important;
        margin-top: 2rem !important;
        padding-top: 3rem !important;
        padding-bottom: 1rem !important;
        margin-bottom: 1rem !important;
        text-decoration: underline;
    }
    
}

@media(min-width: 1326px){
    .F-Title{
        justify-content: center !important;
        text-align: center !important;
        flex-direction: column !important;
        font-size: 50px !important;
        font-weight: 500 !important;
        text-align: center !important;
        position: absolute !important;
        top: 80px !important;
        left: 10% !important;
        right: 10% !important;
        text-shadow: black 2px 2px 10px !important;
    }
    .F-Undertext{
        justify-content: center;
        text-align: center;
        flex-direction: column;
        font-size: 26px;
        position: absolute;
        top: 230px;
        left: 10%;
        width: 80%;
        text-shadow: black 2px 2px 10px;
    
    }
    .f-button{
        justify-content: center !important;
        text-align: center !important;
        position: absolute !important;
        top: 470px !important;
        left: 30% !important;
        right: 30% !important;
        border: none ;
    }
    .btn{
        background-color: #CC0033 !important;
        color: white !important;
        font-size: 26px !important;
        font-weight: bold;
        width: 25% !important;
    }
    .Contact-Title{
        font-size: 35px !important;
        justify-content: center !important;
        text-align: center !important;
        margin-top: 2rem !important;
        padding-top: 3rem !important;
        padding-bottom: 1rem !important;
        margin-bottom: 1rem !important;
        text-decoration: underline;
    }

}

@media (min-width: 1980px){
    .F-Title{
        justify-content: center !important;
        text-align: center !important;
        flex-direction: column !important;
        font-size: 70px !important;
        font-weight: 500 !important;
        text-align: center !important;
        position: absolute !important;
        top: 150px !important;
        left: 10% !important;
        right: 10% !important;
        text-shadow: black 2px 2px 10px !important;
    }
    .F-Undertext{
        justify-content: center;
        text-align: center;
        flex-direction: column;
        font-size: 40px;
        position: absolute;
        top: 350px;
        left: 10%;
        width: 80%;
        text-shadow: black 2px 2px 10px;
    
    }
    .f-button{
        justify-content: center !important;
        text-align: center !important;
        position: absolute !important;
        top: 650px !important;
        left: 30% !important;
        right: 30% !important;
        border: none ;
    }
    .btn{
        background-color: #CC0033 !important;
        color: white !important;
        font-size: 40px !important;
        font-weight: bold;
        width: 20% !important;
    }    
}

@media (min-width: 2506px){
    .F-Title{
        justify-content: center !important;
        text-align: center !important;
        flex-direction: column !important;
        font-size: 90px !important;
        font-weight: 500 !important;
        text-align: center !important;
        position: absolute !important;
        top: 200px !important;
        left: 10% !important;
        right: 10% !important;
        text-shadow: black 2px 2px 10px !important;
    }
    .F-Undertext{
        justify-content: center;
        text-align: center;
        flex-direction: column;
        font-size: 55px;
        position: absolute;
        top: 500px;
        left: 10%;
        width: 80%;
        text-shadow: black 2px 2px 10px;
    
    }
    .f-button{
        justify-content: center !important;
        text-align: center !important;
        position: absolute !important;
        top: 1000px !important;
        left: 30% !important;
        right: 30% !important;
        border: none ;
    }
    .btn{
        background-color: #CC0033 !important;
        color: white !important;
        font-size: 60px !important;
        font-weight: bold;
        width: 20% !important;
    }
}