.Formu {
  max-width: 300px;
  min-width: 240px;
}

.center {
  margin: auto !important;
  width: 50% !important;
  padding: 10px;
  border: 3px solid green;    
}

.captchascale{
  transform:scale(0.77);-webkit-transform:scale(0.77);transform-origin:0 0;-webkit-transform-origin:0 0;
}

.contactSize{
margin: auto !important;
width: 30% !important;    
border: 3px solid rgb(193, 193, 193);  
}

.bg-contactForm{
background-color: rgb(173, 184, 184);
}

.contactBorderColor{
border-color:  solid rgb(193, 193, 193) !important;

}

.contactTextCenter{
  text-align: center;
}
.contactFontSize{
  font-size: 2rem;
}

.captchaCenter {
  margin: auto !important;
  width: 50% !important;
}
.submit-button{
margin-left: 25% !important;
margin-right: 25% !important;
width: 40% !important;
align-self: center !important;
background-color: #CC0033 !important;
color: white !important;
font-size: 20px;
border: none !important;

position: relative !important;
left: 5% !important;
right: 5% !important;
top: 5% !important;
bottom: 5% !important;

margin-top: 1rem !important;
margin-bottom: 1rem !important;


}


.captchaWidth {
max-width: 360px !important;
padding: 10px !important;
}

@media (max-width: 812px){
.contactSize{
margin: auto !important;
width: 45% !important;    
border: 3px solid rgb(193, 193, 193);  
}
.captchaWidth {
  max-width: 240px !important;
  padding: 10px !important;
  }
}

@media (max-width: 420px){
.contactSize{
margin: auto !important;
width: 75% !important;    
border: 3px solid rgb(193, 193, 193);  
}
.submit-button{
margin-left: 25% !important;
margin-right: 25% !important;
width: 45% !important;
align-self: center !important;
background-color: #CC0033 !important;
color: white !important;
font-size: 15px !important;
border: none !important;

position: relative !important;
left: 5% !important;
right: 5% !important;
top: 5% !important;
bottom: 5% !important;

margin-top: 1rem !important;
margin-bottom: 1rem !important;


}
.captchaWidth {
  max-width: 180px !important;
  padding: 10px !important;
  }

}
@media (max-width: 1316){
.submit-button{
  margin-left: 25% !important;
  margin-right: 25% !important;
  width: 30% !important;
  align-self: center !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;

  background-color: #CC0033 !important;
  color: white !important;
  font-size: 16px !important;
  font-weight: bold;
}
}