/* Components */
.Nav{
    color: white !important;
    text-shadow: black 2px 2px 10px;
    font-size: 30px !important;
    margin-left: 40px !important;
    margin-right: 40px !important;
    margin-bottom: 10px !important;
    vertical-align: 0rem !important;
}
.Nav:hover{
    color: #CC0033 !important;
    text-shadow: black 2px 2px 20px;
}
.logo{
    width: 50% !important;
    margin-bottom: 2rem !important;
    margin-top:  2rem !important;
}


@media (min-width: 768px){
    .Nav{
    color: white !important;
    justify-content: center !important;
    text-shadow: black 2px 2px 10px;
    font-size: 20px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    }
    .logo{
    width: 20% !important;
    margin-bottom: 2rem;
    margin-top: 1rem;
    }
}
@media (min-width: 1870px){
    .Nav{
        color: white !important;
        text-shadow: black 2px 2px 10px;
        font-size: 30px !important;
        margin-left: 40px !important;
        margin-right: 0px !important;
        margin-bottom: 10px !important;
        vertical-align: 0rem !important;
    }
    .Nav:hover{
        color: #CC0033 !important;
        text-shadow: black 2px 2px 20px;
    }
    .logo{
        width: 20% !important;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }
}
@media (min-width: 2506px){
    
    .Nav{
        color: white !important;
        text-shadow: black 2px 2px 10px;
        font-size: 50px !important;
        vertical-align: 0rem !important;
        margin-left: 40px !important;
        margin-right: 40px !important;
    }
    .logo{
        width: 25% !important;
        margin-bottom: 4rem !important;
        margin-top: 4rem !important;
        padding-right: 80px !important;
    }
    
}