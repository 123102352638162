.footerCenter {
    display: block;
    margin: auto !important;
    width: 100% !important;
    position: fixed;
    bottom: 0;  
  }

.footer-bg{
    background-color: aquamarine;
    
}

.footer-border{
    max-width: 360px;
}

.footer-mt-2{margin-top: 1.5rem !important}

.footer-md{
    @media min-width: 768px {
        
    }
}

/* .footer-bottom{
    position: fixed;
    bottom:0;
    width:100%;
} */

.mx-8{
    margin-left: 60px;
    margin-right: 60px;
}