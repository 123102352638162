.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Egenmixtrat */

/* .navbar h1:hover {
  cursor: pointer;
  text-decoration: underline;
} */

/* .logo img {
  width: 200px;
  padding-left: 3%;
} */

.f_logo img{
  max-width: 24px;
}

/* .mainPage {
  max-width: 2400px;
  min-width: 1920px;
} */

.footer :hover {
  cursor: pointer;
}

/* .mainCenter {
  margin: auto !important;
  max-width: 1920px;
}

.bg-main{
  background-color: rgb(198, 224, 224);
} */