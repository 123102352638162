.container{
    display: flex;
    align-items: center;
    justify-content: center
}
.firstlinetext{
    text-align: center;
    margin-top: 3rem;
    font-size: 30px;
    text-decoration: underline;
}
.aboutimage1{
    margin-top: 3rem;
    width: 25%;
}
.imagelinetext{    
    margin-top: 3rem;
    margin-left: 5%;
    width: 50%;
    font-size: 18px;
}
.imagelinetext2{    
    margin-top: 3rem;
    margin-right: 5%;
    width: 50%;
    font-size: 18px;
}

.aboutUs{

}

.ProductInfoText{
    text-align: center;
    margin-top: 0.5rem;
    font-size: 30px;
}

.ProductInfoContainer{
    width: 1024px;
}

.ProductLeftMargin{
    margin-left: 0px;
}

.ProductAutoMargin{
    margin: auto;
    width: 50%;
}

.ProductFontSize{
    font-size: 1.5rem;
}