.carousel-caption{
    position: absolute !important;
    
    top: 15% !important;
    bottom: 15% !important;
    left: 15% !important;
    right: 15% !important;

}
.Carousel-Button{
    background-color: transparent !important;
    border: none !important;
    vertical-align: middle !important;
    width: 100% !important;
    font-size: 35px !important;
    font-weight: 600 !important;
    text-shadow: 2px 2px 10px black !important;
    
    
    position: absolute !important;
    top: 0% !important;
    bottom: 0% !important;
    left: 0% !important;
    right: 0% !important;
}

@media (min-width: 1880px){

    .Carousel-Button{
        width: 100% !important;
        font-size: 50px !important;
        font-weight: 700 !important;
    }

}
@media (min-width: 2506px){

    .Carousel-Button{
        width: 100% !important;
        font-size: 80px !important;
        font-weight: 700 !important;
    }

}