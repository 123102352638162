.card-width{
    max-width: 32rem;    
}
.card-img-top{
    position: relative;
    height: 400px;
    width: 300px !important;
    align-self: center !important;
    left: 12.5%;
    right: 12.5%;
}
.image-square{
    background-color: rgb(80, 80, 80);
    width: 400px;
    height: 400px;
}

.card-frame{
    max-width: 50%;
    margin:auto
}

.card-frame-xs{
    max-width: 60%;
    margin:auto
}

.max-heigth{
    height: 32rem;
}
.bg-card
{   
    background-color: rgb(248, 249, 250) !important;
}
.text-card{
    color: rgb(80, 80, 80) !important;
}

.max-heigth-button{
    height: 4rem;
}

.cols{
    columns: md-6;
    columns: lg-6;
}

/* .card-container{
    flex-direction: ;
} */



.flex-container {
    display: flex;
    flex-direction: row;
}

@media (min-width: 360px){
    .card-width{
        width: 100%;
        height: 80;    
    }
    .bg-card{   
    background-color: rgb(248, 249, 250) !important;
    }
    .image-square{
        background-color: rgb(80, 80, 80);
        width: 225px;
        height: 300px;
    }
    .card-img-top{
        position: relative;
        height: 300px;
        width: 225px !important;
        align-self: center !important;
        left: 0;
    }
    .text-card{
        color: rgb(80, 80, 80) !important;
        width: 100%;
    }
}
@media (min-width: 500px){
    .card-width{
        width: 100%;
        height: 80;    
    }
    .bg-card{   
    background-color: rgb(248, 249, 250) !important;
    }
    .image-square{
        background-color: rgb(80, 80, 80);
        width: 100%;
        height: 300px;
    }
    .card-img-top{
        position: relative;
        height: 300px;
        width: 100% !important;
        align-self: center !important;
        left: 0;
    }
    .text-card{
        color: rgb(80, 80, 80) !important;
        width: 100%;
    }
}
@media (min-width: 800px){
    .card-width{
        width: 100%;
        height: 80;    
    }
    .bg-card{   
    background-color: rgb(248, 249, 250) !important;
    }
    .image-square{
        background-color: rgb(80, 80, 80);
        width: 100%;
        height: 200px;
    }
    .card-img-top{
        position: relative;
        height: 200px;
        width: 100% !important;
        align-self: center !important;
        left: 0;
    }
    .text-card{
        color: rgb(80, 80, 80) !important;
        width: 100%;
    }
}
@media (min-width: 1000px){
    .card-width{
        width: 100%;
        height: 80;    
    }
    .bg-card{   
    background-color: rgb(248, 249, 250) !important;
    }
    .image-square{
        background-color: rgb(80, 80, 80);
        width: 100%;
        height: 300px;
    }
    .card-img-top{
        position: relative;
        height: 300px;
        width: 100% !important;
        align-self: center !important;
        left: 0;
    }
    .text-card{
        color: rgb(80, 80, 80) !important;
        width: 100%;
    }
}
@media (min-width: 1366px){
    .card-width{
        width: 100%;
        height: 80;    
    }
    .bg-card{   
    background-color: rgb(248, 249, 250) !important;
    }
    .image-square{
        background-color: rgb(80, 80, 80);
        width: 100%;
        height: 400px;
    }
    .card-img-top{
        position: relative;
        height: 400px;
        width: 100% !important;
        align-self: center !important;
        left: 0;
    }
    .text-card{
        color: rgb(80, 80, 80) !important;
        width: 100%;
    }
}
@media (min-width: 1920px){
    .card-width{
        width: 100%;
        height: 80;    
    }
    .bg-card{   
    background-color: rgb(248, 249, 250) !important;
    }
    .image-square{
        background-color: rgb(80, 80, 80);
        width: 100%;
        height: 400px;
    }
    .card-img-top{
        position: relative;
        height: 400px;
        width: 300px !important;
        align-self: center !important;
    }
    .text-card{
        color: rgb(80, 80, 80) !important;
        width: 100%;
        font-size: 22px;
        text-align: center;
    }
}


/* @media (min-width: 576px) and (orientation: portrait), (min-width: 576px) and (orientation: landscape){
    .class-card{flex-direction: row !important;}

    .class-card img{
        border-top-left-radius: 0.5rem !important;
        border-bottom-left-radius: 0.5rem !important;
        border-bottom-right-radius: 0rem !important;
        border-top-right-radius: 0rem !important;
        width: 360px !important;
    }
    .class-card > div:first-of-type {
        border-bottom-left-radius: 0rem !important;
        border-top-right-radius: 0.5rem !important;
        border-bottom-right-radius: 0.5rem !important;
        width: 250px !important;
    }
} */

/* DAte
.class-card .date{
    position: absolute;
    top: 10px;
    left: 10px;
    width: 60px !important;
    height: 60px !important;
    border-radius: 0.5rem;
    box-shadow: 0.15rem 0.15rem rgba(0,0,0,0.2);
}

.class-card > div {
    border-bottom-left-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
    
}

.class-card img {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
}

@media (min-width: 576px) and (orientation: portrait), (min-width: 576px) and (orientation: landscape){
    .class-card{flex-direction: row !important;}

    .class-card img{
        border-top-left-radius: 0.5rem !important;
        border-bottom-left-radius: 0.5rem !important;
        border-bottom-right-radius: 0rem !important;
        border-top-right-radius: 0rem !important;
        width: 360px !important;
    }
    .class-card > div:first-of-type {
        border-bottom-left-radius: 0rem !important;
        border-top-right-radius: 0.5rem !important;
        border-bottom-right-radius: 0.5rem !important;
        width: 250px !important;
    }
}

@media (min-width: 1366px) and (orientation: landscape){
    .class-card{margin-right: 1rem !important;}
} */